* {
    margin: 0;
    padding: 0;
}

main {
    height: auto;
    margin-bottom: 55px;
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

video{
    height: 99vh;
    width: 99.5%;
    object-fit: fill;
    left: 0;
    top: 0;
    position: absolute;
    border: 2px solid yellow;
}

.hidden {
    display: none !important;
}

.link_color {
    color: #000;
}

.table_img {
    width: 70px;
    height: 70px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.table_img:hover {
    cursor: pointer;
}

.table_img.fullscreen img {
    width: 100%;
    height: 100%;
}

.table_img.fullscreen {
    z-index: 2;
    position: fixed;
    width: 25%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #000;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    transition: 0.5s;
}

.page-item-active .page-link {
    z-index: 0;
}

.dataTables_filter {
    float: left;
}

.dropdown-item:hover {
    background-color: #212529;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    color: #f8f9fa;
}

.table thead th {
    border-bottom: none!important;
}

.table th {
    border-top: none!important;
}

.table th, .table td {
    border-top: none!important;
}

.diagonal-top {
    width: 100%;
    height: 75px;
    -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
    clip-path: polygon(100% 0, 0 0, 0 100%);
}

.diagonal-bottom {
    width: 100%;
    height: 75px;
    -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    margin-top: -75px;
}

.diagonal-top-text {
    color: #343a40;
    text-align: left;
    margin-left: 5px;
}

.diagonal-bottom-text {
    color: #343a40;
    position: relative;
    text-align: right;
    top: 70%;
    margin-bottom: 0;
    margin-right: 5px;
}

.card-body {
    border-radius: 3px;
}

.a-link {
    text-decoration: none;
    cursor: pointer;
}

.a-link:hover {
    text-decoration: none;
}

.status-computer, .status-controller, .status-spare {
    height: 25px;
}

.status-p {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.statuspage-kiosk {
    color: #343a40;
    height: 40px;
    position: relative;
}

.statuspage-controller {
    color: #343a40;
    height: 20px;
    position: relative;
}

.statuspage-domotica {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}  

.statuspage-gameinfo {
    color: #343a40;
    height: 20px;
}

.status-offline {
    background-color: #9ea7ad!important;
}

.status-maintenance {
    width: 50%;
    height: 100%;
    right: 0;
    z-index: 1;
    background-color: #2dccff!important;
}

.status-controller-maintenance {
    width: 50%;
    height: 100%;
    z-index: 1;
    /* position: relative; */
    background-color: #2dccff!important;
}

.status-text {
    position: absolute;
    top: 0;
    left: 0;
}

.status-controller-text {
    position: relative;
    top: 0;
    left: 0;
}

.status-wrapper {
    height: 20px;
    position: relative;
    width: 100%;
}

.status-kiosk-wrapper {
    height: 40px;
    position: relative;
    width: 100%;
}

.status-controller-maintenance + .status-controller-text {
    top: -100%;
}

.status-online {
    background-color: #7cd992!important
}

.status-warning {
    background-color: #f7e463!important;
}

.status-critical {
    background-color: #eb6060!important;
}

.status-text-offline {
    color: #9ea7ad!important;
}

.status-text-maintenance {
    color: #2dccff!important;
}

.status-text-maintenance-legend {
    color: #2dccff!important;
}

.status-text-online {
    color: #7cd992!important
}

.status-text-warning {
    color: #f7e463!important;
}

.status-text-critical {
    color: #eb6060!important;
}

.status-screens-text-warning {
    color: #f1a009!important;
}

.status-game-flag {
    height: 30px;
}

.legend-status-online {
    width: 40px;
    height: 20px;
    background-color: #7cd992!important;
}

.legend-status-warning {
    width: 40px;
    height: 20px;
    background-color: #f7e463!important;
}

.legend-status-critical {
    width: 40px;
    height: 20px;
    background-color: #eb6060!important;
}

.display_none {
    display: none !important;
}

.reveal {
    filter: blur(4px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.reveal:hover {
    filter: none;
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.admin-badge {
    color: #5cb85c;
}

.sortable-item, .ui-sortable-placeholder {
    list-style: none;
    border: 2px solid;
    border-radius: 3px;
    padding: 0.75rem 1.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.sortable-item:first-child {
    list-style: none;
    border: 2px solid;
    border-radius: 3px;
    padding: 0.75rem 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.sortable-item:last-child {
    list-style: none;
    border: 2px solid;
    border-radius: 3px;
    padding: 0.75rem 1.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.warning-icon {
    font-size:18px;
    cursor: pointer;
}

.sortable-item .ui-state-disabled {
    color:#da2424;
}

.ui-sortable {
    margin-bottom: 0;
    overflow: hidden;
}

.select2-results__option--selected {
    display: none;
}

.row {
    display: -webkit-flex;
}

.highscore-table-row {
    height: 75px;
}

.export-container > .copy-export {
    display: none;
}

.export-container:hover > .copy-export {
    display: block;
}

.docs-sidepanel {
    position: relative;
    height: 100%;
}

.move-pixel {
    position: relative;
    top: -1px;
}

.time-size {
    width: 9%;
}

.form-group {
    margin-bottom: 1rem;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color: rgb(49, 48, 48);
}
.comment-field {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid gray;
    overflow: auto;
    height: 30px;
}

.comment-card-field {
    white-space: pre-wrap;
}

.img-thumbnail-show {
    width: 40%;
}

.gg-chevron-up {
    box-sizing: border-box;
    position: relative;
    display: inline;
    margin-left: auto;
    float: right;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px
}
.gg-chevron-up::after {
    content: "";
    display: inline;
    box-sizing: border-box;
    position: absolute;
    margin-left: auto;
    float: right;
    width: 10px;
    height: 10px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    transition: transform 0.5s;
    left: 4px;
    bottom: 2px
}

.list-group-item {
    height: 42px;
}

.list-group-item[aria-expanded=true] > .gg-chevron-up::after{
    transform: rotate(-225deg);
    transition: transform 0.5s;
} 

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.fill img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}