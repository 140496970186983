// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~select2/src/scss/core';
@import '~datatables.net-bs5/css/dataTables.bootstrap5.css';
@import '~jquery-ui/themes/base/all.css';
@import '~highlight.js/styles/obsidian.css';
@import '../css/style.css';

@import '~bootstrap-icons/font/bootstrap-icons.css';

// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
